/* Primary: rgb(168, 207, 58); */
/* Secondary: rgb(177, 192, 130); */
/* Tertiary: rgb(112, 139, 38); */

/* General */
html{
  background: rgb(33, 33, 33);
  color: white;
}
a{
  color: rgb(168, 207, 58);
  cursor: pointer;
}
a:visited{
  color: rgb(88, 109, 30);
}
.container{
  width: 70%;
  min-width: 350px;
  margin: 5em auto;
}
.flex, .flex-start{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex-start{
  justify-content: flex-start;
}
.section-title{
  position: relative;
  padding-right: 10px;
  display: inline-block;
  padding-bottom: 0.25em;
}
.section-title:after{
  content: '';
  position: absolute;
  z-index: -1;
  width: 350px;
  height: 3px;
  left: 0;
  top: 50%;
  background: rgb(168, 207, 58);
  border-radius: 2px;
}

.primary{
  color: rgb(168, 207, 58);
}
.secondary{
  color: rgb(177, 192, 130);
}
.faded{
  color: rgb(183, 183, 183);
}
.white{
  color: white;
}
.bg-primary{
  background: rgb(168, 207, 58);
}
.bg-tertiary{
  background: rgb(112, 139, 38);
}
.bg{
  background: rgb(33, 33, 33);
}
.bg-light{
  background: rgb(79, 79, 79);
}
.bg-dark{
  background: #000;
}


.size-14{
  font-size: 14pt;
}
.size-16{
  font-size: 16pt;
}
.size-20{
  font-size: 20pt;
}
.size-25{
  font-size: 25pt;
}
.size-40{
  font-size: 40pt;
}
.size-44{
  font-size: 44pt;
}

div img.inactive{
  opacity: 0.1;
  pointer-events: none;
}
.invis{
  opacity: 0;
}

/* BadgeList */
.badge-list{
  margin: 1em auto;
  display: flex;
  flex-wrap: wrap;
}
.badge-list span{
  margin: 0.3em 0.5em;
  padding: 0.35em;
  border-radius: 4px;
}

/* PicCanvas */
.pic-canvas{
  position: relative;
  width: 100%;
}
.pic-canvas:after{
  content: '';
  position: absolute;
  z-index: -1;
  width: 18em;
  height: 18em;
  top: 1em;
  left: 1em;
  background: rgb(168, 207, 58);;
  border-radius: 2px;
  box-shadow: #000 5px 5px 10px;
}
.pic-canvas img{
  margin: auto;
  width: 18em;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 5px;
}

/* Nav */
nav{
  position: fixed;
  z-index: 2;
  top: 0;
  width: 90%;
  padding-right: 10%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 5px;
}
nav ul{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
nav li{
  padding: 1em 1em;
  list-style: none;
  color: white;
}

/* Landing */
.landing div{
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}
.landing{
  margin-top: 14em;
  margin-bottom: 13em;
}

/* About */
.about-text p:first-child{
  margin-top: 0;
}

/* Experience */
.exp-tab{
  position: relative;
  padding: 1em .5em;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
.exp-tab:hover{
  background: rgb(79, 79, 79);
}
.exp-tab:after{
  content: '';
  position: absolute;
  width: 60%;
  height: 2px;
  left: 20%;
  top: 3em;
  background: rgb(168, 207, 58);
  transition: all 0.2s ease-in-out;
}
.exp-tab.bg-light:after{
  left: 2.5%;
  width: 95%;
}
.exp-tab-list-container{
  padding: 0 1em;
}
.exp-detail-container{
  width: 80%;
  min-width: 350px;
  height: 15em;
}
.exp-heading{
  margin-top: 1.25em;
}
.exp-heading div{
  padding: 0.25em;
}

/* Projects */
.project-container{
  margin: 0.4em;
  padding: 1.5em;
  padding-top: 0.5em;
  background: rgb(62, 67, 46);
  border-radius: 5px;
  width: 275px;
  height: 21em;
  display: flex;
  flex-direction: column;
}
.project-container p{
  margin: 0;
}
.project-techlist-container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.project-techlist span{
  padding-right: 1em;
  font-size: 9pt;
}
.project-techlist span:last-child{
  padding-right: 0;
}
.project-links a, .project-links span{
  padding-right: 0.5em;
}
.project-links a:last-child .project-links span:last-child{
  padding-right: 0;
}
.project-links img{
  width: 2em;
  opacity: 0.8;
}
.project-links img:hover{
  opacity: 1;
}

/* Contact */
.contact-content{
  margin: auto;
  width: 80%;
  min-width: 300px;
}
.contact-links a{
  padding-right: 1em;
}
.contact-links img{
  width: 4em;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}
.contact-links img:hover{
  opacity: 1;
}

/* Footer */
.footer{
  padding: 2em;
  display: flex;
  justify-content: center;
  white-space: pre;
}

/* QuickContact */
.quick-contact{
  left: 5%;
  top: 0;
  position: fixed;
  width: 3em;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: flex-end;
}
.quick-contact-line{
  position: relative;
  margin: 0 auto;
  width: 0.2em;
  height: 25em;
  border-radius: 1px;
}
.quick-contact-line div{
  position: absolute;
  top: 2em;
  padding: 1em 0;
}
.quick-contact-line img{
  width: 3em;
  position: relative;
  left: -1.5em;
  top: 0;
}
.quick-contact-line a{
  display: block;
  margin-bottom: 0.5em;
}
.quick-contact-line a:last-child{
  margin-bottom: 0;
}
div.quick-contact-hover{
  top: -2.5em;
  left: -1.5em;
  width: 3em;
  text-align: center;
  transition: opacity 0.1s ease-in-out;
}

@media only screen and (min-width: 768px){
  div.quick-contact{
    display: flex;
  }
}